<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Motorista">
          <cadastro-motorista
            v-if="showForm"
            @addedMotorista="showForm = false"
          />
          <editar-motorista v-else-if="edit" @close="edit = false" :motorista="motorista"/>
          <listar-motorista v-else @showForm="showForm = true" @edit="handleEdit" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarMotorista from '@/components/administrativo/motorista/ListarMotorista'
import CadastroMotorista from '@/components/administrativo/motorista/CadastroMotorista'
import EditarMotorista from "@/components/administrativo/motorista/EditarMotorista"

export default {
  name: "Motorista",
  
  data() {
    return {
      edit: false,
      showForm: false,
      page: {
        title: 'Gerenciamento de Motorista',
      },
      breadcrumbs: [
        {
          text: 'Administrativo',
          disabled: false,
        },
        {
          text: 'Motoristas',
          disabled: false,
        },
      ],
    }
  },
  components: {
    ListarMotorista,
    CadastroMotorista,
    EditarMotorista
  },
  methods: {
    handleEdit: function(motorista) {
      this.edit = true
      this.motorista = motorista
    }
  }
}
</script>

<style></style>
