<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa (por email ou cpf)"
          single-line
          hide-details
          @change="handleGetMotorista"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="motoristas"
        sort-by="nr_cpf"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Motoristas registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-icon medium @click="getMotoristas" title="Atualizar"
              >mdi-refresh</v-icon
            > -->
            <v-spacer></v-spacer>
            <v-btn
              v-if="canCreate"
              color="primary"
              dark
              class="mb-2"
              @click="adicionarMotorista"
              >Novo</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on"
                >mdi-format-list-bulleted-square</v-icon
              >
            </template>
            <v-list>
              <template v-if="item.documentos.length > 0">
                <v-list-item
                  v-for="doc in item.documentos"
                  :key="doc.attachment_key"
                  :href="doc.url"
                  target="_blank"
                >
                  <v-list-item-title>{{ doc.description }}</v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item>
                  <v-list-item-title>Sem documentos</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-icon small v-if="canUpdate" class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small v-if="canDelete" @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>

        <template #no-data>
          <v-btn v-if="canList" color="primary" @click="getMotoristas"
            >Reset</v-btn
          >
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
      </v-data-table>
    </div>
    <confirm-dialog @confirm="confirmDelete" v-if="canDelete" />
  </div>
</template>

<script>
// import { ModalCriarEditarPatio } from './ModalCriarEditarPatio'
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import readXlsxFile from 'read-excel-file'
import MotoristasApi from '@/services/tenants/motoristas'
import EmpresaApi from '@/services/tenants/empresa'
import UsuarioApi from '@/services/usuario'
import moment from 'moment'

export default {
  name: 'ListarMotorista',

  components: { ConfirmDialog },
  data() {
    return {
      dialog: false,
      loading: false,
      search: '',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nome', value: 'user.name' },
        { text: 'Email', value: 'user.email' },
        { text: 'CPF', value: 'user.cpf' },
        { text: 'Celular', value: 'user.celular' },
        { text: 'Registro', value: 'user.cnh_registro' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
      usuario: {
        cpf: '',
        name: '',
        celular: '',
        data_nascimento: '2022-01-01',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf_orgao_emissor: '',
        nacionalidade: 'Brasil',
        email: '',
        cnh_registro: '',
        cnh_emissao: '',
        cnh_validade: '',
        cnh_categoria: 'D',
        cnh_uf_emissor: '',
      },
      motorista: {},
    }
  },
  computed: {
    ...mapState('motoristas', ['motoristas']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Motoristas.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo' : 'Editar'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      if (this.motoristas.length > 0) {
        this.motoristas.length = 0
      }
    }
  },

  methods: {
    ...mapActions('patio', ['addMotorista', 'editMotorista']),
    ...mapActions('motoristas', ['getMotorista', 'excluirMotorista']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    async handleGetMotorista(search) {
      search = search.replaceAll('.', '').replaceAll('-', '')
      this.loading = true
      await this.getMotorista({ search })
      this.loading = false
    },

    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null
      readXlsxFile(xlsxfile).then((rows) => {
        rows.forEach((value) => {
          //console.log(value[0] + "" + value[1] + "" + value[2] + "" + value[3])
          const cpf = value[1]
          const nome = value[0]

          if (
            cpf !== null &&
            cpf !== '' &&
            value[0] !== null &&
            value[0] !== ''
          ) {
            if (cpf.replaceAll('.', '').replaceAll('-', '').length == 11) {
              const motorista = MotoristasApi.getMotorista(this.empresaAtual, {
                search: cpf.replaceAll('.', '').replaceAll('-', ''),
              })

              if (motorista.length > 0 && motorista[0]) {
                console.log('tem')
              } else {
                this.motorista.cpf = cpf.replaceAll('.', '').replaceAll('-', '')
                this.motorista.name = value[0]
                this.motorista.celular = value[3]
                this.motorista.cnh_registro = value[2]
                this.motorista.data_nascimento = '01/01/2022'
                this.motorista.data_nascimento = moment(
                  this.motorista.data_nascimento
                ).format('YYYY-MM-DD')

                this.motorista.cnh_emissao = '01/01/2022'
                this.motorista.cnh_emissao = moment(
                  this.motorista.cnh_emissao
                ).format('YYYY-MM-DD')

                this.motorista.cnh_validade = '01/01/2022'
                this.motorista.cnh_validade = moment(
                  this.motorista.cnh_validade
                ).format('YYYY-MM-DD')

                this.motorista.cnh_categoria = ''
                this.motorista.cnh_uf_emissor = ''
                this.motorista.rg = ''
                this.motorista.cnh_categoria = ''
                this.motorista.rg_orgao_emissor = ''
                this.motorista.rg_uf_orgao_emissor = ''
                this.motorista.nacionalidade = ''
                this.motorista.email = ''
                try {
                  this.submitAdicionarMotorista(
                    cpf.replaceAll('.', '').replaceAll('-', ''),
                    nome.replaceAll('.', '').replaceAll('-', '')
                  )
                } catch (err) {
                  this.successMessage(`Motorista adicionado com sucesso`)
                }
              }
            } else {
              console.log('CPF INVALIDO')
            }
          } else {
            console.log('CPF VAZIO')
          }
        })
      })
    },

    async submitAdicionarMotorista(cpf, name) {
      let usuario = {}

      usuario.cpf = cpf
      usuario.name = name
      usuario.is_motorista = true

      if (usuario.data_nascimento === null) {
        usuario.data_nascimento = '01/01/2022'
      }
      usuario.data_nascimento = moment(usuario.data_nascimento).format(
        'YYYY-MM-DD'
      )

      if (usuario.cnh_validade === null) {
        usuario.cnh_validade = '01/01/2022'
      }
      usuario.cnh_validade = moment(usuario.cnh_validade).format('YYYY-MM-DD')

      if (usuario.cnh_emissao === null) {
        usuario.cnh_emissao = '01/01/2022'
      }
      usuario.cnh_emissao = moment(usuario.cnh_emissao).format('YYYY-MM-DD')

      const usuarioRetorno = await UsuarioApi.getUsuarioByCpf(cpf)

      if (usuarioRetorno == undefined) {
        usuario.username = 'teste' + cpf + '-'
        usuario.password = '@@usuario2021..'
        usuario.password_confirm = this.usuario.password
        usuario = await UsuarioApi.addUsuario({ ...usuario })
      } else {
        usuario = usuarioRetorno
      }

      this.motorista.documentos = []
      this.motorista.user = usuario.public_id
      this.motorista.empresa = this.empresaAtual.public_id

      try {
        await EmpresaApi.adicionarMotorista(this.motorista)
        console.log(cpf)
        this.successMessage('Motorista adicionado com sucesso!')
      } catch (err) {
        this.successMessage(`ERRO`)
      }
    },

    adicionarMotorista() {
      this.$emit('showForm')
    },

    editItem(motorista) {
      this.$emit('edit', motorista)
    },

    deleteItem(motorista) {
      this.itemToDelete = motorista

      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: `Deseja mesmo excluir o motorista ${motorista.user.name}?`,
      })
    },

    async confirmDelete() {
      const nomeMotorista = this.itemToDelete.user.name
      try {
        await this.excluirMotorista(this.itemToDelete)
        this.successMessage(`Motorista ${nomeMotorista} excluído com sucesso!`)
      } catch (error) {
        this.errorMessage(
          `Erro ao excluir o motorista ${nomeMotorista}: ${error}`
        )
      }
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editMotorista(this.editedItem) // REVER.. Editar
      } else {
        console.log(this.editedItem)
        this.addMotorista(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
