<template>
  <div>
    <div class="preview2">
      <img
        v-if="motorista.foto"
        :src="motorista.foto.url | ''"
        alt="image-preview"
        class="image-preview"
      />
      <div v-else class="image-preview"></div>
    </div>
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados"> Dados gerais <v-icon>mdi-home-city</v-icon></v-tab>
      <v-tab href="#foto"> Foto <v-icon>ti-settings</v-icon></v-tab>
      <v-tab href="#documentos">
        Documentos <v-icon>mdi-format-list-bulleted-square</v-icon></v-tab
      >

      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-motorista
            :value="motorista.user"
            :hasButtonSave="true"
            @close="close"
            @save="save"
          />
        </v-tab-item>
        <v-tab-item value="foto">
          <etapa-foto-biometria
            :fotoBiometria="fotoBiometria"
            v-model="motorista.foto"
            :hasButtonSave="true"
            @updateFoto="updateFoto"
            @close="close"
          />
        </v-tab-item>

        <v-tab-item value="documentos">
          <etapa-documentos
            ref="etapaDocumentos"
            hasButtonSave
            @close="close"
            @updateDocs="updateDocs"
            v-model="motorista.documentos"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import DadosMotorista from '@/components/administrativo/motorista/EtapasCadastro/EtapaDadosMotorista'
import EtapaDocumentos from './EtapasCadastro/EtapaDocumentos'
import EtapaFotoBiometria from './EtapasCadastro/EtapaFotoBiometria'
import UsuarioApi from '@/services/usuario'

export default {
  name: 'EditarMotorista',

  components: { DadosMotorista, EtapaDocumentos, EtapaFotoBiometria },
  props: {
    motorista: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: '',
      fotoBiometria: {},
    }
  },
  created() {
    this.fotoBiometria = this.motorista.foto || {}
  },
  methods: {
    ...mapActions('patio', [
      'editMotorista',
      'updateDocumentosMotorista',
      'updateFotoMotorista',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    close: function () {
      this.$emit('close')
    },
    save: async function () {
      try {
        this.motorista.user.cpf = this.motorista.user.cpf
          .replaceAll('.', '')
          .replace('-', '')
        await UsuarioApi.editarUsuario(this.motorista.user)
        this.$emit('close')
        this.successMessage(
          `Motorista ${this.motorista.user.name} editado com sucesso`
        )
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    async updateFoto(foto) {
      try {
        await this.updateFotoMotorista({
          motorista: this.motorista,
          foto: foto,
        })
        this.$emit('close')
        this.successMessage(
          `Motorista ${this.motorista.user.name} editado com sucesso`
        )
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    async updateDocs(listaDocs) {
      try {
        await this.updateDocumentosMotorista({
          motorista: this.motorista,
          documentos: listaDocs,
        })
        this.$emit('close')
        this.successMessage(
          `Motorista ${this.motorista.user.name} editado com sucesso`
        )
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  },
}
</script>

<style scopped lang="scss">
.preview2 {
  margin: auto;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div,
  > img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: auto;
    border: 3px solid #ccc;
  }

  > div {
    border: 3px solid #ccc;
    background: #ededed;
  }
}
.image-preview {
  background-color: #ccc;
}
</style>
